<template>
  <list-container>
    <AuctionsList type="all" :create="true" />
  </list-container>
</template>

<script>
import AuctionsList from "@/components/Auctions/AuctionsList"
import ListContainer from "@/components/Auctions/ListContainer"

export default {
  name: "All",
  components: {ListContainer, AuctionsList}
}
</script>